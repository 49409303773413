import React, { useState } from "react";
// import {data} from './data'
// import Skill from './Skill'
import "./Skills.css";
function Skills() {
  //     const skills = data;
  // const i = 0;
  //     const [skill,
  //         setSkill] = useState(skills[i]);
  // function getSkill (event){
  //     console.log(skill)
  //     console.log(event)
  //  setSkill(skills[event])
  //  console.log(skill)
  //     }
  return (
    <div>
      <section className="skills" id="skills">
        <div className="max-width">
          <h2 className="title">My skills</h2>
          <div className="skills-content">
            <div className="column left">
              <div className="text">My creative skills & experiences.</div>
              <h1>Per Scholas</h1>
              <p>Learned HTML, CSS, and JavaScript</p>
              <h1>General Assembly </h1>
              <p>Learned Node.JS, REACT, and Git</p>
              {/* <a href="#">Read more</a> */}
            </div>
            <div className="column right">
              <div className="bars">
                <div className="info">
                  <span>HTML</span>
                  <span>90%</span>
                </div>
                <div className="line html"></div>
              </div>
              <div className="bars">
                <div className="info">
                  <span>CSS</span>
                  <span>60%</span>
                </div>
                <div className="line css"></div>
              </div>
              <div className="bars">
                <div className="info">
                  <span>JavaScript</span>
                  <span>80%</span>
                </div>
                <div className="line js"></div>
              </div>
              <div className="bars">
                <div className="info">
                  <span>Node</span>
                  <span>50%</span>
                </div>
                <div className="line php"></div>
              </div>
              <div className="bars">
                <div className="info">
                  <span>React</span>
                  <span>70%</span>
                </div>
                <div className="line mysql"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="skills__list">
                {skills.map((skill, index) => 
                    <button key={index} value={index} onClick={event=>getSkill(event.currentTarget.value)}>
                        <img className="skills__logo" src={skill.image} alt=""/>

                    </button>
                )}
            </div>
            <div className="skills__window">
                {< Skill skill = {
                    skill
                } />
} 
            </div> */}
    </div>
  );
}

export default Skills;
