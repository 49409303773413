import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faCode,
  faPaintBrush,
} from "@fortawesome/free-solid-svg-icons";

function Services() {
  return (
    <div>
      <section className="services" id="services">
        <div className="max-width">
          <h2 className="title">My services</h2>
          <div className="serv-content">
            <div className="card">
              <div className="box">
                <FontAwesomeIcon icon={faPaintBrush} />
                <div className="text">Web Design</div>
                <p>
                  Available services include: digital mockups, graphic design,
                  and research all in one comprehensive proposal.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="box">
                <FontAwesomeIcon icon={faCode} />
                <div className="text">Web Development</div>
                <p>
                  Whether you're starting from scratch or have an exsting
                  website, I can help to take it to the next level using the
                  most optimal web development technology available.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="box">
                <FontAwesomeIcon icon={faChartLine} />
                <div className="text">Consultation</div>
                <p>
                  Have an idea but not quite sure where to go from there?
                  Contact me to set up a time to chat and I'll let you know the
                  best resources to achive your goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services;
