import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Skills from "./Skills";
// import Projects from "./Projects";
// import Welcome from "./Welcome";
import Home from "./Home";
import About from "./About";
import Services from "./Services";
import Contact from "./Contact";
import Projects from "./Projects";
function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/">
            <Home />
            <About />
            <Services />
            <Skills />
            <Projects/>
            <Contact />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
