import React from "react";
import {
  faEnvelope,
  faMapMarkerAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Contact() {
  return (
    <div>
      <section className="contact" id="contact">
        <div className="max-width">
          <h2 className="title">Contact me</h2>
          <div className="contact-content">
            <div className="column left">
              <div className="text">Get in Touch</div>
              <p>
                Whether you need a project done, a Full Stack Developer or just
                need some advice, send me a message and I'll do my best to
                connect.
              </p>
            </div>
            <div className="column right">
              <div className="icons">
                <div className="row">
                  <FontAwesomeIcon icon={faUser} />
                  <div className="info">
                    <div className="head">Name</div>
                    <div className="sub-title">Crae Ewan</div>
                  </div>
                </div>
                <div className="row">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <div className="info">
                    <div className="head">Address</div>
                    <div className="sub-title">Brooklyn, New York</div>
                  </div>
                </div>
                <div className="row">
                  <FontAwesomeIcon icon={faEnvelope} />
                  <div className="info">
                    <div className="head">Email</div>
                    <div className="sub-title">cewan28@gmail.com</div>
                  </div>
                </div>
              </div>
              {/* <div className="text">Message me</div>
              <form className="contact-form" action="#" method="POST">
                        <div className="fields">
                            <div className="field name">
                                <input type="text" className="fullname" placeholder="Name">
                            </div>
                            <div className="field email">
                                <input type="text" className="email-input" placeholder="Email">
                            </div>
                        </div>
                        <div className="field">
                            <input type="text" className="subject" placeholder="Subject">
                        </div>
                        <div className="field textarea">
                            <textarea className="message" cols="30" rows="10" placeholder="Message.."></textarea>
                        </div>
                        <div className="button-area">
                            <button className="send-msg" type="submit" name="send">Send message</button>
                        </div>
                    </form> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
