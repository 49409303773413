import React, { useState, useEffect } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import { faAngleUp, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useScrollPosition from "@react-hook/window-scroll";

function Home(props) {
  const data = [
    "Web Developer",
    "Project Manager",
    "Freelancer",
    "Bartender",
    "Wine Connosieur",
    "Coffee Afficiando",
  ];
  const [index, setIndex] = useState(0);

  console.log(index);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex(index + 1);

      console.log(index);
      if (index === 5) {
        setIndex(0);
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [index]);
  const scrollY = useScrollPosition(60);
  console.log(scrollY);
  const scrollToTop = () => {
    window.scrollToTop({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <div
        onclick={scrollToTop}
        className={`scroll-up-btn ${scrollY > 500 ? "show" : " "} `}
      >
        <FontAwesomeIcon icon={faAngleUp} />
      </div>
      <nav className={`navbar ${scrollY > 20 ? "sticky" : " "} `}>
        <div className="max-width">
          <div className="logo">
            <Link to="/">
              Portfo<span>lio</span>
            </Link>
          </div>
          <ul className="menu">
            <li>
              <Link to="/" className="menu-btn">
                Home
              </Link>
            </li>
            <li>
              <a href="#about" className="menu-btn">
                About
              </a>
            </li>
            <li>
              <a href="#services" className="menu-btn">
                Services
              </a>
            </li>
            <li>
              <a href="#skills" className="menu-btn">
                Skills
              </a>
            </li>
            <li>
              <a href="#projects" className="menu-btn">
                Projects
              </a>
            </li>
            <li>
              <a href="#contact" className="menu-btn">
                Contact
              </a>
            </li>
          </ul>
          <div className="menu-btn">
            <FontAwesomeIcon icon={faBars} />
          </div>
        </div>
      </nav>
      <div>
        <section className="home" id="home">
          <div className="max-width">
            <div className="row">
              <div className="home-content">
                <div className="text-1">Hello, my name is</div>
                <div className="text-2">Crae Ewan</div>
                <div className="text-3">
                  And I'm a <span className="typing">{data[index]}</span>
                </div>
                <a href="#contact" className="menu-btn">
                  Hire me
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
