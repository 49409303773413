import React, { useState, useEffect } from "react";
import myFace from "./images/me.jpg";
import { Link } from "react-router-dom";
import resume from "./files/myresume 2021.pdf";
function About() {
  const data = [
    "Web Developer",
    "Project Manager",
    "Freelancer",
    "Bartender",
    "Wine Connosieur",
    "Coffee Afficiando",
  ];
  const [index, setIndex] = useState(0);
  console.log(resume);
  console.log(index);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex(index + 1);

      console.log(index);
      if (index === 5) {
        setIndex(0);
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [index]);
  return (
    <div>
      <section className="about" id="about">
        <div className="max-width">
          <h2 className="title">About me</h2>
          <div className="about-content">
            <div className="column left">
              <img src={myFace} alt=""></img>
            </div>
            <div className="column right">
              <div className="text">
                I'm Crae and I'm a{" "}
                <span className="typing-2">{data[index]}</span>
              </div>
              <p>
                Depending on who you are, I am either your next best hire or
                your next best friend. Connect with me to find out.
              </p>
              <Link to={resume} target="blank">
                Download Resume
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
