import React from "react";
import "./Projects.css";

function Projects() {
  return (
    <div className="projects__container">
      <section className="projects" id="projects">
        <div className="max-width">
          <h2 className="title">My projects</h2>
          <div className="carousel owl-carousel">
            <div className="card">
              <a href="https://spotify-clone-app-1b53f.web.app/"><div className="box">
                {/* <img src="images/profile-1.jpeg" alt=""> */}
                <div className="text">Spotify Clone App</div>
                <p>
                  Spotify Clone app utilizes the Spotify Music Api, React, and
                  Firebase. 
                </p>
              </div></a>
            </div>
            <div className="card">
            <a href="https://clone-app-7c10f.web.app/"><div className="box">
                {/* <img src={mov1} alt=""></img> */}
                <div className="text">Amazon Clone App</div>
                <p>The Amazon Clone App is a working clone of the Amazon store home page with full eccommerce functionality provided by Stripe.</p>
              </div></a>
            </div>
            <div className="card">
            <a href="https://react-responisve-login.web.app/"><div className="box">
                {/* <img src="images/profile-3.jpeg" alt=""> */}
                <div className="text">React Responsive Login Example</div>
                <p>React example project of common responisve design elements for Login pages converted from HTML, CSS, and JS to REACT.</p>
              </div></a>
            </div>
            <div className="card">
            <a href="https://wildfire-tracker-2f32c.web.app/"><div className="box">
                {/* <img src="images/profile-4.jpeg" alt=""> */}
                <div className="text">Wildfire Tracker</div>
                <p>A single page web app using Google Map API, National Weather Service API and React.</p>
              </div></a>
            </div>
          </div>
        </div>
      </section>
      {/* <div classNameName="project__window">
      </div>
      <div classNameName="project__content">
        <h1 classNameName="p__title">
          {projects[0].title}
        </h1>
      </div> */}
    </div>
  );
}

export default Projects;
